
import * as vercelRuntime$QtT4GSU84A from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.5.0_magicast@0.3.5_rollup@4.28.1/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "vercel",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$QtT4GSU84A, defaults: {} }
}
        